article {
    padding : spacer-y(1) 0;
    
    p {
        //font-size: $base-font-size * 1.3;
        font-weight : 300;
        white-space : pre-line;
    }
    
    figure {
        width         : 27%;
        float         : left;
        margin-right  : spacer-x(1);
        margin-bottom : spacer-y(1) * .5;
    }
}

.article-with-img {
    
    span {
        padding-bottom : spacer-y(1) * .5;
    }
    
    p:nth-child(2) {
        font-style  : italic;
        font-size   : $base-font-size * 1.1;
        font-weight: 400;
        line-height : $base-line-height * .7;
    }
    
}

@include media-breakpoint-up(xs) {
    article p {
        text-align : left;
    }
}

@include media-breakpoint-up(md) {
    article p {
        text-align : justify;
    }
}