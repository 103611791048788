.container {
    max-width: $max-width;
    margin: 0 auto;
}

@include media-breakpoint-up(xs){
    .container {
        padding: 0 1rem;
    }
}
@include media-breakpoint-up(xl){
    .container {
        padding: 0 2rem;
    }
}
@include media-breakpoint-up(xxl){
    .container {
        padding: 0;
    }
}