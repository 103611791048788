ul {
    list-style      : none;
    justify-content : flex-start;
    padding: 0;
}

@include media-breakpoint-up(xs){
    ul.nav {
        display: none;
    }
}

@include media-breakpoint-up(md){
    ul.nav {
        display: flex;
    }
}
