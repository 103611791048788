$grid-columns : 12;

.row {
    position  : relative;
    flex-flow : row wrap;
    display   : flex;
}
[class*='col-'] {
    width : 100%;
    display: flex;
}
// grid
@each $breakpoint in map-keys($media-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        @for $columns from 1 through $grid-columns {
            .col-#{$breakpoint}-#{$columns} {
                
                $fraction : ($columns / $grid-columns);
                
                align-items: center;
                flex  : 0 0 auto;
                width : calc(99.999999% * #{$fraction});
            }
        }
    }
}