* {
    color : $base_color;
}
h2, h3 {
    font-weight : 100;
    text-align  : center;
    width       : 100%;
}
//
//
//
// header
.header {
    background  : $header-bg-color;
    line-height : 50px;
    a {
        text-transform : uppercase;
    }
}
//
//
//
// therapy
.therapy {
    background     : url(images/pc/img.png) no-repeat 50% 50%;
    padding-bottom : spacer-y(2);
    .therapy-items {
        background : rgba(color(light), .9);
        border     : 1px solid rgba(color(dark), .2);
        .s-content {
            margin  : 0;
            padding : spacer-x(1)*.7 spacer-y(1);
            .s-item-content {
                font-size : $base-font-size * .9;
            }
        }
    }
}
@include media-breakpoint-up(xs) {
    .therapy {
        min-height          : 80rem;
        background-position : 50% 100%;
        .therapy-items {
            width         : 100%;
            min-height    : 7rem;
            margin-bottom : spacer-y(1);
        }
    }
}
@include media-breakpoint-up(md) {
    .therapy {
        min-height          : 50rem;
        background-position : 50% 100%;
        .therapy-array {
            display         : flex;
            flex-flow       : row wrap;
            justify-content : space-around;
        }
        .therapy-items {
            width         : 45%;
            min-height    : 7rem;
            border-radius : 3px;
        }
    }
}
@include media-breakpoint-up(xl) {
    .therapy {
        .therapy-items {
            width      : 30%;
            text-align : center;
            .s-content {
                margin  : 0;
                padding : spacer-x(1)*.7 spacer-y(1);
                .s-item-content {
                    font-size : $base-font-size * .9;
                }
            }
        }
    }
}
@include media-breakpoint-up(xxl) {
    .therapy {
        min-height          : 80rem;
        height              : 90rem;
        background-position : 50% 50%;

        @include therapy;

        .therapy-array {
            display : initial;
        }
        .therapy-items {
            width           : 20rem;
            min-height      : 7rem;
            border-radius   : 3px;
            align-items     : center;
            justify-content : center;
            display         : flex;
            position        : relative;
            box-shadow      : 0 0 90px 7px lighten(color(dark), 40%);
        }
    }
}
//
//
//
// about
.about {
    background : url('images/gippokrat.jpg') no-repeat;
    .o-about-title,
    .o-about-desc {
        background-color : rgba($base-bg-color, .65);
        text-align       : left;
    }
    .o-about-title {
        margin-bottom : spacer-x(1);
        font-weight   : 300;
    }
}
.o-about {
    display   : flex;
    flex-flow : row wrap;
}
@include media-breakpoint-up(xs) {
    .about {
        background-position : 100% 0;
    }
    .o-about-title,
    .o-about-desc {
        padding : spacer-x(1) spacer-y(1);
    }
    .o-about-title {
        font-size      : $base-font-size * 1.5;
        text-transform : uppercase;
    }
    .o-about-desc {
        font-size : inherit;
    }
}
@include media-breakpoint-up(md) {

    .about {
        background-size : cover;
        min-height          : 800px;
        margin          : spacer-x(3) 0;
        .o-about {
            padding : spacer-x(3);
        }
        .o-about-title,
        .o-about-desc {
            background-color : rgba($base-bg-color, .7);
            padding          : spacer-x(1) spacer-y(1);
            text-align       : left;
        }
        .o-about-title {
            margin-bottom : spacer-x(1);
            font-size     : 2.4rem;
            font-weight   : 300;
        }
        .o-about-desc {
            font-size : $base-font-size + 5px;
        }
    }
}
//
//
//
// partners
.partners {
    background : $secondary-bg-color;
    padding    : spacer-y(1) 0;
    .o-partner-content {
        height          : 20rem;
        justify-content : center;
        align-items     : center;
        display         : flex;
        div:nth-child(1n) {
            flex: 1 1 auto;
        }
        .s-content {
            margin: spacer-x(0);
        }
    }
}
@include media-breakpoint-up(xs){
    .o-partner-content {
        flex-direction: column;
    }
}
@include media-breakpoint-up(md){
    .o-partner-content {
        flex-direction: row;
    }
}
//
//
//
// license
@include media-breakpoint-up(xs) {
    .license {
        display : none;
    }
}
@include media-breakpoint-up(md) {
    .license {
        display : initial;
    }
}
//
//
//
// footer info
.footer {
    background : $base-footer-bg;
    h2, h3, div, span, p, a {
        color : color(light);
    }
    h2, h3 {
        text-align : inherit;
    }
    ul {
        li {
            &:not(:first-child) {
                margin : spacer-x(1) spacer-y(0);
            }
            span {
                color       : color(light);
                font-weight : 200;
            }
        }
    }
    .owner-info-container-person {
        flex-flow       : row wrap;
        justify-content : space-around;
        & div:last-child {
            flex-basis : 100%;
            width      : 100%;
        }
    }
    .owner-names {
        text-align : right;
        font-size  : $base-font-size * 1.8;
        font-weight: $base-font-weight;
        .owner-firstName {
            font-weight : 700;
        }
    }
    .owner-profession-name {
        text-align    : right;
        margin-bottom : spacer-x(2);
    }
    figure {
        border-radius : 50%;
    }
    .progress-slider__item-desc {
      padding: spacer-x(0) spacer-y(1)
    }
}
.footer-bottom {
    background-color : $base-footer-bottom-bg;
    .container {
        display         : flex;
        flex-flow       : row nowrap;
        justify-content : space-between;
        padding         : spacer-x(2);
    }
}
.address {
    width : 100%;
    font-weight: $base-font-weight;
    .phoneNumber {
        padding : spacer-x(1) 0;
    }
}
@include media-breakpoint-up(xs) {
    .footer {
        ul {
            padding-right : spacer-x(0);
        }
        .row {
            flex-wrap : wrap;
        }
        [class^='col-'] {
            flex-wrap  : wrap;
            margin-top : spacer-x(1);
        }
        .owner-info-container-person {
            & div:not(:last-child) {
                flex-basis : 40%;
            }
        }
        .owner-names {
            font-size : $base-font-size * 1.5 !important;
        }
        figure {
            width      : 8rem;
            height     : 8rem;
            box-shadow : 1px 1px 15px 0 lighten($base-color, 30%);
            margin     : spacer-x(0) auto;
        }
    }
    .owner-info-container-person {
        display        : flex;
        flex-direction : column-reverse;
    }
    .owner-profession-desc {
        padding : spacer-x(0) spacer-x(0);
        .progress-slider div {
            height : 21rem;
        }
    }
    .footer-bottom .container {
        flex-direction : column;
        div {
            font-size : $base-font-size * .8;
        }
    }
    .address {
        .phoneNumber {
            font-size     : $base-font-size * 2;
            font-weight: 500;
            border-top    : 1px solid $base-footer-bottom-bg;
            border-bottom : 1px solid $base-footer-bottom-bg;
            text-align    : center;
        }
    }

}
@include media-breakpoint-up(md) {
    .footer {
        ul {
            padding-right : spacer-x(3);
        }
        .footer-all-info {
            padding : spacer-x(2) 0;
        }
        .row {
            flex-flow   : row wrap;
            align-items : baseline;
        }
        [class^='col-'] {
            flex-wrap : wrap;
        }
        .owner-info-container-person {
            flex-direction : initial;
            margin-bottom  : spacer-x(1);
            & div:not(:last-child) {
                flex-basis : 40%;
            }
            & div:first-child {
                flex-basis : 60%;
            }
        }
        .owner-profession-desc {
            padding : spacer-x(0) spacer-x(1);
            .progress-slider div {
                height : 12rem;
            }
        }
    }
    .footer-bottom .container {
        flex-direction : row;
        div {
            font-size : inherit;
        }
    }
    .address {
        .phoneNumber {
            border     : none;
            text-align : left;
        }
    }
}
@include media-breakpoint-up(xxl) {
    .footer-bottom .container {
        padding : spacer-x(2) 0;
    }
}
