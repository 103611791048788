
.s-item-title {
    border-bottom : 1px solid $base-color;
}
@include media-breakpoint-up(xs) {
    
    .s-content {
        margin-bottom : spacer(x, 1);
    }
    
}
@include media-breakpoint-up(md) {
    .s-content {
        margin : 0 spacer-y(1) spacer-x(2);
        .s-item-title {
            text-align  : center;
            margin      : spacer-x(1) 0;
            padding-top : spacer-x(1);
            min-height  : 90px;
        }
    }
    
}