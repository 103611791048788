$media-gutter : spacer-x(1) !default;

.s-media-body {
    display               : grid;
    grid-template-columns : repeat(2, 1fr);
    grid-auto-flow        : row;
    align-items           : center;
}

figure {
    display  : block;
    overflow : hidden;
    
    img {
        width : 100%;
    }
}

@include media-breakpoint-up(xs) {
    .s-media-items {
        flex : 0 0 100%;
    }
}

@include media-breakpoint-up(md) {
    .s-media-items {
        flex   : 1;
        margin : 0 $media-gutter;
    }
}