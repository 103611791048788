.services-block {
    .container {
        height          : 75rem;
        display         : flex;
        flex-direction  : column;
        justify-content : space-between;
    }
    [class*='services-'] {
        background : rgba($base-bg-color, .9);
        .row {
            align-items : baseline;
        }
        .s-item-title {
            text-transform : uppercase;
        }
    }
}
@include media-breakpoint-up(xs) {
    .services-block {
        background              : none;
        -webkit-background-size : initial;
        background-size         : initial;
    }
}
@include media-breakpoint-up(md) {
    
    .services-block {
        background              : url(../images/pc/img3.jpg) no-repeat;
        -webkit-background-size : 100% 80%;
        background-size         : 100% 80%;
        background-position     : 50% 50%;
    }
    .services-top,
    .services-bottom {
        [class^='col-'] {
            align-items : baseline;
        }
    }
}
@include media-breakpoint-up(xl) {
    .services-block {
        -webkit-background-size : 100% 90%;
        background-size         : 100% 90%;
    }
}
