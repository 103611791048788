.title {
    max-height : 1050px;
}
@include media-breakpoint-up(xs) {

    .title {
        background : transparent;
        h2 {
            font       : {
                family : $base-font-family;
                size   : $base-font-size * 1.8;
                weight : 100;
            }
            text-align : center;
            color      : color(dark);
            span {
                font-weight : 700;
            }

        }
    }
}
@include media-breakpoint-up(sm) {

    .title {
        .row {
            justify-content : center;
        }
    }
}
@include media-breakpoint-up(md) {

    .title {

        background   : {
            image    : url(../images/top-bg.jpg);
            repeat   : no-repeat;
            size     : 100% 100%;
            position : 100% 0%;
        }
    ;

        height : 550px;
        @media (orientation : portrait) {
            height : 500px;
        }
        .row {
            justify-content : flex-end;
            h2 {
                font-size : $base-font-size * 2;
                text-align : left;
                padding    : 0 4rem;
                @media (orientation : landscape) {
                    font-size : $base-font-size * 2.6;
                }
            }
        }
    }
}
@include media-breakpoint-up(lg) {

    .title {
        background-position : 100% 0%;
        background-size     : 100% 100%;
        height              : 700px;
        .row h2 {
            font-size : $base-font-size * 2.7;
        }
    }
}
@include media-breakpoint-up(xxl) {
  .title {
      background-position : 0 0;
      background-size     : 75% 100%;
  }
}
@include media-breakpoint-up(xxl) {

    .title {
        background-size     : 65% 100%;
        height              : 850px;
        .row h2 {
            font-size : $base-font-size * 4;
            padding: 0
        }
    }
}
