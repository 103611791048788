@function color($key) {
    @if map-has-key($colors, $key) {
        @return map-get($colors, $key);
    }
    
    @warn "Unknown `#{$key}` in $colors.";
    @return null;
}

$base-bg-color: lighten(color(dark), 85%);
$base-color: darken(color(light), 75%);

$secondary-bg-color: lighten(color(dark), 73%);

$header-bg-color: darken(color(light), 15%);

$base-footer-bg: lighten(color(dark), 5%);
$base-footer-bottom-bg: rgba(darken(color(dark), 20%), .5);
