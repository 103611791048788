// base width
$max-width : 1440px;

// typography
$base-font-size : 16px;
$base-line-height : 1.75;

// fonts
$base-font-family : Roboto, Hevletica, Arial, sans-serif;
$base-font-weight : 300;

// colors
$colors : (
    primary: #007BFF,
    secondary: #6C757D,
    success: #28A745,
    info: #17A2B8,
    warning: #FFC107,
    danger: #DC3545,
    light: #F8F9FA,
    dark: #343A40
);

// breakpoints
$media-breakpoints : (
    // Extra small screen / phone
    xs: 0,
    // Small screen / phone
    sm: 544px,
    // Medium screen / tablet
    md: 768px,
    // Large screen / desktop
    lg: 992px,
    // Extra large screen / wide desktop
    xl: 1200px,
    // super extra
    xxl: 1920px
) !default;

// therapy items positions
$points : (
    1: (
        x: 20rem,
        y: 7rem
    ),
    2: (
        x: 45rem,
        y: -5rem
    ),
    3: (
        x: 60rem,
        y: -1rem
    ),
    4: (
        x: 60rem,
        y: 3rem
    ),
    5: (
        x: 57rem,
        y: 8rem
    ),
    6: (
        x: 33rem,
        y: 8rem
    ),
    7: (
        x: 7rem,
        y: -15rem
    ),
    8: (
        x: 1rem,
        y: -42rem
    ),
    9: (
        x: 6rem,
        y: -68rem
    )
);
