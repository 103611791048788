.react-tabs {
    padding-top: spacer-y(2);
}

.react-tabs__tab-list {
    display : flex;
    //flex-wrap: nowrap;
    align-content: space-evenly;
    justify-content: space-evenly;
    border-bottom: none !important;
    
    li {
        width: 40% !important;
        background : rgba(lighten(color(primary), 35%), .4);
    }

}

.react-tabs__tab {
    border-bottom: none !important;
    border-color : #fff !important;
    transition: all .2s ease-in-out;
}

.react-tabs__tab--selected {
    border-radius: initial !important;
    background : rgba(lighten(color(primary), 25%), .7) !important;
    transition: all .2s ease-in-out;
}