@function therapy-pos($direction, $item, $point: $points) {
    @return map-get(map-get($point, $item), $direction)
}

@mixin therapy {
    @for $i from 1 through 9 {
        .therapy-array .item-#{$i} {
            transform: translate(therapy-pos(x, $i), therapy-pos(y, $i));
        }
    }
}