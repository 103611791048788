.baloon {
    text-align: center;
    background : rgba(color(info), .8);
    color : color(light);
    //width: 20rem;
    //height: 7rem;
    //display: flex;
    //justify-content: center;
    //align-items: center;
    //font-size: $base-font-size * 1.3;
    //box-shadow: 2px 2px 10px color(dark);
    //border-radius: 8px;
}